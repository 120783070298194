const HeaderMenuShow = {
	init: function () {
		$(".header-menu-icon").on("click", function () {
			$(this).toggleClass("is-open");
			$(".header-menu").toggleClass("is-open");
		});
	}
};

const Accordion = {
	init: function () {
		$(".accordion__trigger").on("click", function () {
			if (window.matchMedia("(max-width: 768px)").matches) {
				$(this).next(".accordion__contents").slideToggle();
				$(this).toggleClass("is-open");
			}
		});
	},
};
const YoutubeBlock = {
	init: function () {
		$(document).ready(function () {
			$("iframe").wrap($("<div>").addClass("js-iframe-wrapper"));
		});
	}
};

const loopArticles = {
	init: function () {
		if (!$("#nav-below").get(0)) return;
		let offset = $("#nav-below").offset().top;
		let windowsHeight = $(window).height();
		let url = "/wp-content/themes/ksjblog2023/add-article.php";
		let postNumNow = 10; /* 最初に表示されている記事数 */
		let postNumAdd = 10; /* 追加する記事数 */
		let flag = false;
		let categoryName = $('.article-list').data('category');

		$(window).on("scroll", function () {
			let scrollPosition = windowsHeight + $(window).scrollTop();
			if (scrollPosition >= offset) {
				if (!flag) {
					flag = true;
					$.ajax({
						type: "POST",
						url: url,
						data: {
							post_num_now: postNumNow,
							post_num_add: postNumAdd,
							category: categoryName
						}
					}).done(function (response) {
						$(".article-list").append(response);
						offset = $("#nav-below").offset().top;
						postNumNow += postNumAdd;
						flag = false;
					})
				}
			}
		});
	}
};

(function ($) {
	HeaderMenuShow.init();
	Accordion.init();
	YoutubeBlock.init();
	loopArticles.init();
})(jQuery);
